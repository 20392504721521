<template>
  <v-row class="bg">
    <v-container>
      <template>
        <div class="pointer" ref="photos">
          <!-- <gallery
            :images="images"
            :index="index"
            @close="index = null"
          ></gallery> -->
          <div
            class="image"
            v-for="(image, imageIndex) in images"
            :key="imageIndex"
            @click="index = imageIndex"
            :style="{
              backgroundImage: 'url(' + image + ')',
              width: '350px',
              height: '250px'
            }"
          ></div>
        </div>
      </template>
    </v-container>
  </v-row>
</template>

<script>
// import VueGallery from "vue-gallery";
export default {
  name: "Photos",
  // components: {
  //   gallery: VueGallery
  // },
  data: function() {
    return {
      images: [
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/photos/leadership.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/photos/new-church.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/photos/youth-leading.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/joy.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/busy.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/friends.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/family.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/joy2.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/kids.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/pray.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/pray2.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/progress.jpg",
        "https://d2jr1lht6rcaaw.cloudfront.net/justchurch/progress2.jpg",
       ],
      index: null
    };
  }
};
</script>

<style lang="scss" scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 10px;
}

.pointer {
  cursor: pointer;
}

.bg {
  background-image: url("https://clothing-image-bank.s3-ap-southeast-2.amazonaws.com/web/other/milkshake.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
